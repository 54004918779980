import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import * as React from 'react';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  },
  '& .MuiDialog-paper': {
    width: '100%',
    height: 'fit-content',
    overflow: 'visible',
    [theme.breakpoints.down('md')]: {
      width: '90%'
    }
  }
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: children ? 2 : 0, pt: (theme) => theme.spacing(2) }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 0,
            top: -40,
            color: 'white'
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
};

export interface ModalProps {
  keepMounted?: boolean;
  children?: React.ReactNode;
  open: boolean;
  title?: string;
  onClose?: () => void;
}

export default function Modal(props: ModalProps) {
  const { children, title, open, onClose, keepMounted } = props;

  return (
    <div>
      <BootstrapDialog
        keepMounted={!!keepMounted}
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle onClose={onClose}>{title}</BootstrapDialogTitle>
        <DialogContent>{children}</DialogContent>
      </BootstrapDialog>
    </div>
  );
}
