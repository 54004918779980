import { Box, Button, Chip, CircularProgress, InputBase, TextField, Theme } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { IconSearch } from '../assets/icons/search';
import { SpecializationArea } from '../interfaces/specializationArea';
import { HideAtMd } from './primitives/hide-at-md';
import { SxProps } from '@mui/system';
import { ScrollableBox } from './primitives/scrollable-box';

export interface AutocompleteFieldProps {
  theme: Theme;
  loading: boolean;
  values: SpecializationArea[];
  onChange: (value: string) => void;
  onSuburbSelect: (value: SpecializationArea[]) => void;
  options: SpecializationArea[];
}

export const AutocompleteField = (props: AutocompleteFieldProps) => {
  const { theme, options, values, onChange, loading, onSuburbSelect } = props;
  const selectedValue = values;
  const chipSx: SxProps = {
    fontSize: '16px !important',
    color: `${theme.palette.primary.main} !important`,
    '&:hover': { color: theme.palette.primary.main }
  };
  const isSpecializationArea = (
    value: (string | SpecializationArea)[]
  ): value is SpecializationArea[] =>
    value.every(
      (item: string | SpecializationArea): item is SpecializationArea => typeof value !== 'string'
    );

  const onSearchClick = () => {
    let queryString = 'https://buyer.listingloop.com.au/buyer/#/signup?';
    values.forEach((value, index) => {
      if (index == 0) {
        queryString =
          queryString + `state=${value.state}&postcode=${value.postCode}&suburb=${value.suburb}`;
      } else {
        queryString =
          queryString + `&state=${value.state}&postcode=${value.postCode}&suburb=${value.suburb}`;
      }
    });
    window.open(queryString);
  };
  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          bgcolor: 'white',
          borderRadius: '40px',
          display: 'flex',
          width: '100%',
          padding: '11px'
        }}
        marginTop="150px"
      >
        <HideAtMd theme={theme}>
          <IconSearch
            fill={theme.palette.secondary.main}
            height="24px"
            width="24px"
            marginLeft="15px"
            marginRight={'10px'}
          />
        </HideAtMd>

        <Autocomplete
          multiple
          id="tags-filled"
          freeSolo
          options={options}
          getOptionLabel={(option) => `${option.suburb}, ${option.state}`}
          // defaultValue={[]}
          onChange={(event, value) => {
            console.log(typeof value);
            onSuburbSelect(isSpecializationArea(value) ? value : selectedValue);
          }}
          sx={{
            flexWrap: 'nowrap'
          }}
          value={values}
          loading={loading}
          fullWidth
          renderTags={(value: SpecializationArea[], getTagProps) => {
            return (
              <ScrollableBox className="scrollable-box">
                {value
                  .map((option, index) => (
                    <Chip
                      deleteIcon={<CloseIcon sx={chipSx} />}
                      variant="outlined"
                      label={`${option.suburb}, ${option.state}`}
                      {...getTagProps({ index })}
                    />
                  ))
                  .reverse()}
              </ScrollableBox>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              onChange={(e) => {
                onChange(e.target.value);
              }}
              variant="standard"
              sx={{ ml: 1, flex: 1, paddingRight: '20px' }}
              placeholder="Search for suburbs"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <div
                        style={{
                          border: '1px solid #ddd',
                          borderRadius: '100px',
                          padding: '5px 6px 0px'
                        }}
                      >
                        <CircularProgress color="inherit" size={16} />
                      </div>
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
                disableUnderline: true
              }}
            />
          )}
        />
        <Button
          sx={{
            borderRadius: '50px',
            color: 'white',
            textTransform: 'none',
            boxShadow: 'none',
            padding: '15px 35px',
            [theme.breakpoints.down('md')]: {
              padding: '10px 20px',
              display: 'none'
            }
          }}
          onClick={() => {
            onSearchClick();
          }}
          variant="contained"
        >
          Search
        </Button>
      </Box>
      <Button
        sx={{
          borderRadius: '50px',
          color: 'white',
          textTransform: 'none',
          boxShadow: 'none',
          padding: '10px 35px',
          display: 'none',
          mt: '10px',
          [theme.breakpoints.down('md')]: {
            display: 'block'
          }
        }}
        onClick={() => {
          onSearchClick();
        }}
        variant="contained"
      >
        Search
      </Button>
    </>
  );
};
