import { styled } from '@mui/material';
import { Box } from '@mui/system';

export const ScrollableBox = styled(Box)({
  display: 'flex',
  flexWrap: 'nowrap',
  overflow: 'auto',
  '.scrollable-box::-webkit-scrollbar': {
    display: 'none'
  }
});
