const BASE_URL_MAPBOX_GEOCODER = 'https://api.mapbox.com/geocoding/v5';
const accessToken =
  'pk.eyJ1IjoibGlzdGluZ2xvb3AiLCJhIjoiY2pvcnFlNWx3MGlvczN3cGJtMGYzbDVseCJ9.' +
  'tckPwiTnRWsAeUeAQBx8lw';

const createSuggestUrl = (text: string, type: string): string => {
  const url = new URL(
    `${BASE_URL_MAPBOX_GEOCODER}/mapbox.places/${encodeURIComponent(text)}.json?`
  );

  url.searchParams.append('access_token', accessToken);
  url.searchParams.append('cachebuster', new Date().getTime() + '');
  url.searchParams.append('autocomplete', 'true');
  url.searchParams.append('country', 'AU');
  url.searchParams.append('types', type);
  url.searchParams.append('limit', '10');
  return url.toString();
};

const getFormattedContext = (suggestion) => {
  const contexts = suggestion.context;
  const suburbContext = contexts.find((it) => it.id.includes('locality'));
  const postcodeContext = contexts.find((it) => it.id.includes('postcode'));
  const regionContext = contexts.find((it) => it.id.includes('region'));

  return {
    suburb: (suburbContext && suburbContext.text) || '',
    postcode: (postcodeContext && postcodeContext.text) || '',
    stateAbbr: (regionContext && regionContext.short_code.replace('AU-', '')) || '',
    state: (regionContext && regionContext.text) || ''
  };
};

const preprocessSuburbSuggestion = (suggestion): string => {
  const context = getFormattedContext(suggestion);
  let result = suggestion.place_name;
  result = result.replace(', Australia', '');
  result = result.replace(context.state, context.stateAbbr);
  return `${result}, ${context.postcode}`;
};

const fetchSuburbByCenter = async (center): Promise<string> => {
  const url = createSuggestUrl(`${center.lng},${center.lat}`, 'address');
  const response = await window.fetch(url);
  const json = await response.json();
  if (!(json.features && json.features.length)) {
    return '';
  }

  const result = json.features[0];
  if (!preprocessSuburbSuggestion(result)) {
    return '';
  }
  const context = getFormattedContext(result);
  return `${context.suburb}, ${context.stateAbbr}, ${context.postcode}`;
};

export const fetchSuburbsSuggestion = async (value: string) => {
  const url = createSuggestUrl(value, 'locality');
  const response = await window.fetch(url);
  const json = await response.json();

  const centers = json.features.map((it) => ({
    lng: it.center[0],
    lat: it.center[1]
  }));

  const promises: Promise<string>[] = centers.map((it) => fetchSuburbByCenter(it));
  return Promise.all(promises).then((values) => values.filter((it) => !!it));
};
