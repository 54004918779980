import PlayArrowSharpIcon from '@mui/icons-material/PlayArrowSharp';
import { Box, Button, CircularProgress, Theme, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { CraftGql_Body_NeoField, CraftGql_Home_Home_Entry } from '../../graphql-types';
import { CurveWithArrow } from '../assets/icons';
import { Pattern1 } from '../assets/icons/pattern/pattern1';
import '../assets/styles/style.css';
import { AutocompleteField } from '../components/autocomplete';
import Layout from '../components/layout';
import Modal from '../components/modal';
import { Absolute } from '../components/primitives/absolute';
import { Width80Box } from '../components/primitives/Width80Box';
import VideoPlayIcon from '../components/video-play-icon';
import { REUSABLE_COMPONENT } from '../componentsEnum';
import { AuthContext } from '../context/auth';
import { SpecializationArea } from '../interfaces/specializationArea';
import { fetchSuburbsSuggestion } from '../services/mapbox-service';

export interface Data {
  craftGqlHomeHomeEntry: CraftGql_Home_Home_Entry;
}

export const renderContentBlocks = (
  item: CraftGql_Body_NeoField,
  theme: Theme,
  isMobile?: boolean
) => {
  const Component = REUSABLE_COMPONENT[item.typeHandle];
  if (!item || !item.typeHandle) {
    return null;
  }
  return <Component item={item} theme={theme} isMobile={isMobile} />;
};
export interface IndexPageProps {
  data: Data;
}

const IndexPage = ({ data }: IndexPageProps) => {
  const theme: Theme = useTheme();
  const [isMobile, setIsMobile] = useState<boolean>(false);

  /************************ STYLE **************************/
  const sxProps: { [className: string]: SxProps } = {
    heroSection: {
      background: `url(${data.craftGqlHomeHomeEntry.heroBackgroundImage[0].url})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      paddingTop: '115px',
      [theme.breakpoints.down('md')]: {
        paddingTop: '60px'
      }
    },
    heroContent: {
      background: theme.palette.primary.main,
      padding: '28px',
      [theme.breakpoints.up('md')]: {
        borderRadius: '41px 41px 41px 0',
        padding: '35px 45px',
        width: '556px'
      }
    },
    heroTitle: {
      fontFamily: 'Tropiline',
      fontWeight: 500,
      textAlign: 'center',
      position: 'relative'
    },
    heroSubtitle: {
      marginY: '16px',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        fontSize: '24px'
      }
    },
    playIcon: {
      alignItems: 'center',
      bgcolor: theme.palette.secondary.main,
      borderRadius: '400px',
      justifyContent: 'center',
      display: 'flex',
      height: '45px',
      marginLeft: '10px',
      width: '45px'
    },
    watchButton: {
      color: 'white',
      fontSize: '14px',
      marginLeft: '20px',
      padding: '8px 15px 8px 30px',
      border: '2px solid #fff',
      borderRadius: '50px'
    },
    searchBoxContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '320px',
      margin: '0 auto',
      position: 'relative',
      [theme.breakpoints.down('md')]: {
        padding: '20px'
      }
    }
  };
  /************************ END STYLE **************************/

  // const { user } = useContext(AuthContext);
  const [loading, setLoading] = React.useState(false);
  const [suburbSuggestions, setSuburbSuggestions] = React.useState<SpecializationArea[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedSuburbs, setSelectedSuburbs] = React.useState<SpecializationArea[]>([]);

  useEffect(() => {
    window.addEventListener('scroll', () =>
      setIsMobile(window.innerWidth < theme.breakpoints.values.md)
    );

    return () =>
      removeEventListener('scroll', () =>
        setIsMobile(window.innerWidth < theme.breakpoints.values.md)
      );
  }, []);

  const onInputValueChanged = async (value: string) => {
    if (value.length) {
      setLoading(true);
      let res = await fetchSuburbsSuggestion(value);
      let formattedRes = res.map((v) => {
        let split = v.split(',');
        return {
          suburb: split[0].trim(),
          state: split[1].trim(),
          postCode: split[2].trim()
        };
      });
      let suggestions = formattedRes.filter((res) => {
        let result = selectedSuburbs.some((suburb) => {
          return res.postCode == suburb.postCode;
        });
        return !result;
      });
      setSuburbSuggestions(suggestions);
      setLoading(false);
    }
  };

  const closeModal = () => setModalOpen(false);
  const openModal = () => setModalOpen(true);

  return (
    <AuthContext.Consumer>
      {(contextObj) => {
        return (
          <Box>
            <Box sx={sxProps.heroSection}>
              <Width80Box>
                <Box sx={sxProps.heroContent}>
                  <Box display="flex" flexDirection="column" alignItems="center" my="35px">
                    <Typography sx={sxProps.heroTitle} variant="h1">
                      {data.craftGqlHomeHomeEntry.heroTitle}
                      {contextObj?.user ? `, ${contextObj?.user.name}` : ''}
                      <Absolute top="-35px" left="-20px">
                        <Pattern1 color="white" height={40} width={36} />
                      </Absolute>
                    </Typography>

                    <Typography color="white" variant="h4" sx={sxProps.heroSubtitle}>
                      {data.craftGqlHomeHomeEntry.heroSubtitle}
                    </Typography>

                    <Button
                      onClick={openModal}
                      endIcon={
                        <Box sx={sxProps.playIcon}>
                          <PlayArrowSharpIcon />
                        </Box>
                      }
                      sx={sxProps.watchButton}
                    >
                      Watch video
                    </Button>
                  </Box>
                </Box>

                <Box sx={sxProps.searchBoxContainer}>
                  <Absolute top={-5} left="80px">
                    <CurveWithArrow color={theme.palette.primary.main} height={182} width={90} />
                  </Absolute>
                  <AutocompleteField
                    values={selectedSuburbs}
                    onChange={(value) => onInputValueChanged(value)}
                    onSuburbSelect={(value) => {
                      setSelectedSuburbs(value);
                      setSuburbSuggestions([]);
                    }}
                    theme={theme}
                    options={suburbSuggestions}
                    loading={loading}
                  />
                </Box>
              </Width80Box>
            </Box>

            {data.craftGqlHomeHomeEntry.body.map((item, i) => (
              <Box
                key={item.typeHandle + i}
                my={item.typeHandle === 'ctaButton' ? '10px' : { xs: '30px', md: '100px' }}
              >
                {renderContentBlocks(item, theme, isMobile)}
              </Box>
            ))}

            <Modal open={modalOpen} onClose={closeModal}>
              <Box position="relative" paddingBottom="56.25%">
                <ReactPlayer
                  controls
                  fallback={<CircularProgress color="primary" size={32} />}
                  url={data.craftGqlHomeHomeEntry.videoUrl}
                  playIcon={<VideoPlayIcon />}
                  width="100%"
                  height="100%"
                  style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    left: '0px',
                    top: '0px'
                  }}
                />
              </Box>
            </Modal>
          </Box>
        );
      }}
    </AuthContext.Consumer>
  );
};

export const query = graphql`
  query HomePageQuery {
    craftGqlHomeHomeEntry {
      heroTitle
      heroSubtitle
      propertyMatchesTitle
      videoUrl
      body {
        ... on CraftGQL_body_text_BlockType {
          typeHandle
          bodyText
        }
        ... on CraftGQL_body_whiteSpaceElement_BlockType {
          id
          height
          typeHandle
        }
        ... on CraftGQL_body_image_BlockType {
          typeHandle
          heroImage {
            url
          }
        }
        ... on CraftGQL_body_propertyMatches_BlockType {
          typeHandle
          textTitle
          textSubtitle
        }
        ... on CraftGQL_body_illustrationBlock_BlockType {
          richTextContent
          typeHandle
          imageElement {
            url
          }
        }
        ... on CraftGQL_body_ctaButton_BlockType {
          typeHandle
          buttonTitle
          buttonLink
        }
        ... on CraftGQL_body_downloadApp_BlockType {
          typeHandle
          richText
          textSubtitle
          appStoreLink
          googlePlayLink
        }
        ... on CraftGQL_body_carousel_BlockType {
          typeHandle
          carouselCard {
            ... on CraftGQL_carouselCard_card_BlockType {
              carouselImage {
                url
              }
              carouselText
            }
          }
        }
        ... on CraftGQL_body_features_BlockType {
          typeHandle
          textTitle
          featureCard {
            ... on CraftGQL_featureCard_card_BlockType {
              cardImage {
                url
              }
              imageBackgroundColor
              cardTitle
              cardSubTitle
              cardLinkText
              cardLink
            }
          }
        }
        ... on CraftGQL_body_ribbonSection_BlockType {
          id
          textTitle
          typeHandle
          primaryButton {
            ... on CraftGQL_primaryButton_button_BlockType {
              id
              buttonLabel
              buttonDestinationUrl
            }
          }
        }
        ... on CraftGQL_body_agentsBuyersDotPointsSection_BlockType {
          id
          agentsDotPoints {
            col1
          }
          imageElement {
            url
          }
          listingLoopAgentPointsHeading
          listingLoopAgentPointsLink
          listingLoopAgentPointsLinkLabel
          listingLoopBuyerPointsHeading
          listingLoopBuyersPointsLink
          listingLoopBuyersPointsLinkLabel
          buyersDotPoints {
            col1
          }
          typeHandle
        }
        ... on CraftGQL_body_featuredNews_BlockType {
          id
          textTitle
          textSubtitle
          typeHandle
        }
      }
      propertyMatchesDescription
      heroBackgroundImage {
        url
      }
    }
  }
`;

IndexPage.Layout = Layout;
export default IndexPage;
